import React from 'react';
import './App.css';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Copyright from './components/Copyright/Copyright';
import { NavRoute } from './components/NavRoute/NavRoute';
import CreateUser from './pages/CreateUser/CreateUser';
import ListUsers from './pages/ListUsers/ListUsers';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './Theme';
import ListMemorials from './pages/ListMemorials/ListMemorials';
import EditMemorial from './pages/EditMemorial/EditMemorial';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <NavRoute exact component={Home} path="/home" />
          <NavRoute exact component={ListMemorials} path="/atendimentos" />
          <NavRoute exact component={EditMemorial} path="/atendimentos/:id"/>
          <NavRoute exact component={CreateUser} path="/users/create" permission="ADMIN" />
          <NavRoute exact component={ListUsers} path="/users/list" permission="ADMIN" />

        </Switch>
        <Copyright />
      </Router>
    </ThemeProvider>
  );
}

export default App;
