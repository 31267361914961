import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme, Typography, LinearProgress, Grid, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import { deleteUser, listUsers, updateUser } from './services';
import { useEffect } from 'react';
import { Dialog } from '../../components/Dialog/Dialog';
import { CustomModal } from '../../components/Modal/CustomModal';
import SnackBar from '../../components/Snackbar/SnackBar';

export interface User {
    username: string;
    email: string;
    id: number;
}

export interface UserUpdate {
    password: string;
    id: number | undefined;
}
const useHelperTextStyles = makeStyles(() => ({
    root: {
        marginLeft: 0
    }
}));
const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
    },
    paper: {
        marginTop: theme.spacing(16),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            fontWeight: 600,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

export default function ListUsers() {
    const classes = useStyles();
    const helperTextStyles = useHelperTextStyles();

    const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
    const [usersList, setUsersList] = useState<User[] | []>([]);
    const [openDialogApproval, setOpenDialogApproval] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [selectedUser, setSelectedUser] = useState<number | undefined>(undefined);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<string | undefined>('');
    const [passwordError, setPasswordError] = useState<string | undefined>('');

    const [isSnackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<string>('');
    const [isSnackError, setSnackError] = useState<boolean>(false);

    const sendListUsersRequest = async () => {
        setIsRequestLoading(true);
        let listUsersRequest = await listUsers();
        setIsRequestLoading(false);
        if (listUsersRequest.res) {
            setUsersList(listUsersRequest.data);
        }
    }

    const sendDeleteUser = async () => {
        setIsRequestLoading(true);
        let deleteUserRequest = await deleteUser(selectedUser);
        setIsRequestLoading(false);
        if (deleteUserRequest.res) {
            setSnackError(false);
            setSnackMessage("Sucesso!")
            setSnackOpen(true);
            sendListUsersRequest();
        }else{
            setSnackError(true);
            setSnackMessage("Erro ao excluir usuário.");
            setSnackOpen(true);
        }
    }

    const sendUpdateUser = async () => {
        setIsRequestLoading(true);
        let updateUserRequest = await updateUser({ id: selectedUser, password: password });
        setIsRequestLoading(false);
        if (updateUserRequest.res) {
            setSnackError(false);
            setSnackMessage("Sucesso!")
            setSnackOpen(true);
            sendListUsersRequest();
        }else{
            setSnackError(true);
            setSnackMessage("Erro ao editar usuário.");
            setSnackOpen(true);
        }
    }

    const handleChangePassword = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPassword(event.target.value as string);
        setPasswordError('');
    };

    const handleChangeConfirmPassword = (event: React.ChangeEvent<{ value: unknown }>) => {
        setConfirmPassword(event.target.value as string);
        setConfirmPasswordError('');
    };

    useEffect(() => {
        sendListUsersRequest();
    }, []);

    useEffect(() => {
        if (confirmPassword !== password) {
            setConfirmPasswordError("A senha deve ser igual")
        } else {
            setConfirmPasswordError("");
        }
    }, [confirmPassword, password])

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Lista de Usuários
                </Typography>
                <Dialog
                    openDialog={openDialogApproval}
                    setOpenDialog={setOpenDialogApproval}
                    dialogTitle="Tem certeza que deseja excluir esse usuário?"
                    primaryButtonText="Confirmar"
                    onClickPrimaryButton={() => {
                        setOpenDialogApproval(false);
                        sendDeleteUser();
                    }}
                    secondaryButtonText="Cancelar"
                    onClickSecondaryButton={() => {
                        setOpenDialogApproval(false);
                    }}
                />
                <CustomModal
                    dialogTitle="Editar usuário selecionado"
                    openDialog={openEditModal}
                    setOpenDialog={setOpenEditModal}
                    primaryButtonText="Confirmar"
                    onClickPrimaryButton={() => {
                        setOpenEditModal(false);
                        sendUpdateUser();
                    }}
                    secondaryButtonText="Cancelar"
                    onClickSecondaryButton={() => {
                        setOpenEditModal(false);
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                label="Nova Senha"
                                name="password"
                                autoComplete="password"
                                type="password"
                                id="password"
                                onChange={handleChangePassword}
                                value={password}
                                error={!!passwordError}
                                helperText={passwordError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                label="Confirmar Nova Senha"
                                name="confirmPassword"
                                autoComplete="password"
                                type="password"
                                id="confirm-password"
                                onChange={handleChangeConfirmPassword}
                                value={confirmPassword}
                                error={!!confirmPasswordError}
                                helperText={confirmPasswordError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                </CustomModal>
                <div style={{ width: '100%', height: '20px' }}>{isRequestLoading && (<LinearProgress />)}</div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Id</StyledTableCell>
                                <StyledTableCell align="left">Usuário</StyledTableCell>
                                <StyledTableCell align="left">Email</StyledTableCell>
                                <StyledTableCell align="center">Editar</StyledTableCell>
                                <StyledTableCell align="center">Excluir</StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {usersList.length > 0 && (usersList.map((user) => (
                                <StyledTableRow key={user.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {user.id}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{user.username}</StyledTableCell>
                                    <StyledTableCell align="left">{user.email}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <IconButton onClick={() => {
                                            setOpenEditModal(true);
                                            setSelectedUser(user.id)
                                        }}>
                                            <EditIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <IconButton onClick={() => {
                                            setOpenDialogApproval(true);
                                            setSelectedUser(user.id)
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </StyledTableCell>

                                </StyledTableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <SnackBar message={snackMessage} isOpen={isSnackOpen} severity={isSnackError ? "error" : "success"} handleClose={() => setSnackOpen(false)}/>
        </Container>
    )
}