import React, { Fragment} from 'react';
import {
    Route, Redirect
} from "react-router-dom";
import Appbar from '../Appbar/Appbar';
import jwt_decode from "jwt-decode";

interface NavRouteProps {
    exact: boolean | undefined;
    path: string;
    component: any;
    permission?: string;
}

export interface Session {
    id: number;
    username: string;
    email: string;
    roles: Array<string>;
    accessToken: string;
}

interface DecodedToken {
    id: number;
    exp: number;
    iat: number;
}
export const NavRoute = ({ exact, path, component: Component, permission }: NavRouteProps) => {

    const validateToken = () => {
        const session = sessionStorage.getItem('iv-crm');
        if(session !== null && session !== "\"\""){
            const token: Session = JSON.parse(session);
            const decoded_token: DecodedToken = jwt_decode(token.accessToken);
            let currentDate = new Date();
            if(decoded_token.exp * 1000 > currentDate.getTime()){
                if(permission === undefined){
                    return true;
                }
                const hasPermission = checkPermissions(token, permission);
                if(hasPermission){
                    return true;
                }
                return false;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    const checkPermissions = (session: Session, permission: string | undefined) => {
        const permissionsArray = session.roles;
        if(permissionsArray.includes("ROLE_"+permission)){
            return true;
        }else{
            return false;
        }
    }

    return (
        <Route exact={exact} path={path} render={(props) => (
            validateToken() ? (
                <Fragment>
                    <Appbar />
                    <Component {...props} />
                </Fragment>
            ) : (<Redirect to={{ pathname: "/" }} />))} />
    )
}