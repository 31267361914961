import axios from 'axios';
import { Session } from '../../components/NavRoute/NavRoute';
import { UserUpdate } from './ListUsers';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL
});

export const listUsers = async () => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
        if(session !== null){
            const sessionBody: Session = JSON.parse(session);
            token = sessionBody.accessToken;
          }
    try {
      const response = await api.get("api/user/list", {
        headers: {
          "x-access-token": token
        }
       });
      return { res: true, data: response.data };
    } catch (error) {
      return { res: false, data: error.response.data };
    }
  };

  export const deleteUser = async (id: number | undefined) => {
    const session = sessionStorage.getItem('iv-crm');
    let token = '';
          if(session !== null){
              const sessionBody: Session = JSON.parse(session);
              token = sessionBody.accessToken;
            }
      try {
        const response = await api.delete(`api/user/${id}`, {
          headers: {
            "x-access-token": token
          }
         });
        return { res: true, data: response.data };
      } catch (error) {
        return { res: false, data: error.response.data };
      }
    };

    export const updateUser = async (data: UserUpdate) => {
      const session = sessionStorage.getItem('iv-crm');
      let token = '';
            if(session !== null){
                const sessionBody: Session = JSON.parse(session);
                token = sessionBody.accessToken;
              }
        try {
          const response = await api.put('api/user/update', data, {
            headers: {
              "x-access-token": token
            }
           });
          return { res: true, data: response.data };
        } catch (error) {
          return { res: false, data: error.response.data };
        }
      };