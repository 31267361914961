import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Close } from '@material-ui/icons';
import { Button, Grid, IconButton, Modal } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface DialogProps {
  dialogTitle: string;
  description?: string;
  primaryButtonText: string;
  onClickPrimaryButton: Function;
  secondaryButtonText?: string;
  onClickSecondaryButton?: Function;
  openDialog: boolean;
  setOpenDialog: Function;
  children?: ReactNode;
}

const Dialog = ({
  openDialog,
  setOpenDialog,
  dialogTitle,
  description,
  primaryButtonText,
  onClickPrimaryButton,
  secondaryButtonText,
  onClickSecondaryButton,
  children,
}: DialogProps) => {
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        position: 'absolute',
        width: 580,
       
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        borderRadius: '10px',
        padding: theme.spacing(2, 4, 3),
        outline: 'none',
      },
      upload: {
        height: 200,
      },
      comments: {
        marginTop: '45px',
      },
    })
  );

  const [modalStyle] = React.useState(getModalStyle);

  const classes = useStyles();

  return (
    <Modal
      open={openDialog}
      onClose={() => setOpenDialog()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid container style={modalStyle} className={classes.paper}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          <IconButton
            id="close-dialog"
            onClick={() => setOpenDialog(!openDialog)}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid
          container
          alignItems="center"
          direction="row"
        >
          <ModalWarning>{dialogTitle}</ModalWarning>
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-start"
          direction="row"
        >
          <ModalTextContent>{description}</ModalTextContent>
          <ModalContent>{children}</ModalContent>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="flex-end"
          direction="row"
        >
          {onClickSecondaryButton && secondaryButtonText && (
            <Button
              id="dialog-secondary-button"
              onClick={() => onClickSecondaryButton()}
            >
              <ModalTextButton>{secondaryButtonText}</ModalTextButton>
            </Button>
          )}

          <Button
            id="dialog-primary-button"
            variant="contained"
            color="primary"
            onClick={() => onClickPrimaryButton()}
            style={{ marginLeft: 40 }}
          >
            {primaryButtonText}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

const ModalWarning = styled.h1`
  font-size: 25px;
  font-weight: bold;
  line-height: 35px;
`;

const ModalTextContent = styled.p`
  font-size: 16px;
  line-height: 25px;
`;
const ModalContent = styled.div``;
const ModalTextButton = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0e4b82;
`;

export { Dialog };
