import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { LinearProgress, FormControl, Divider, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SnackBar from '../../components/Snackbar/SnackBar';
import { editAtendimento, editMemorial, getAtendimentos, getToken, getMemorial } from './services';
import CPFMask from '../../components/Masks/CPFMask';
import DateMask from '../../components/Masks/DateMask';
import HourMask from '../../components/Masks/HourMask';
import { AtendimentoRequest } from '../../models/createAtendimentoRequest';
import { MemorialRequest } from '../../models/memorialRequest';
import { useParams } from 'react-router-dom';
import { adjustTime } from '../../utils';

const useHelperTextStyles = makeStyles(() => ({
    root: {
        marginLeft: 0
    }
}));

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(16),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(8, 0, 2),
    },
    formControl: {
        width: '100%',
    },
    helperText: {
        marginLeft: 0,
    },
    progress: {
        marginTop: '1em',
    },
    divider: {
        margin: '1em 0'
    },
    alert: {
        marginBottom: '1em'
    }
}));


export default function EditMemorial() {
    let { id } = useParams<{ id?: string }>();
    const classes = useStyles();
    const helperTextStyles = useHelperTextStyles();

    const [token, setToken] = useState<string>('');
    const [lateName, setLateName] = useState<string>('');
    const [lateCPF, setLateCPF] = useState<string>('');
    const [lateBirthday, setLateBirthday] = useState<string>('');
    const [lateDeathday, setLateDeathday] = useState<string>('');
    const [notes, setNotes] = useState<string>('');

    const [funeralLocation, setFuneralLocation] = useState<string>('');
    const [funeralCity, setFuneralCity] = useState<string>('');
    const [funeralState, setFuneralState] = useState<string>('');
    const [funeralStart, setFuneralStart] = useState<string>('');
    const [funeralEnd, setFuneralEnd] = useState<string>('');
    const [funeralStartTime, setFuneralStartTime] = useState<string>('');
    const [funeralEndTime, setFuneralEndTime] = useState<string>('');

    const [burialLocation, setBurialLocation] = useState<string>('');
    const [burialCity, setBurialCity] = useState<string>('');
    const [burialState, setBurialState] = useState<string>('');
    const [burialStart, setBurialStart] = useState<string>('');
    const [burialEnd, setBurialEnd] = useState<string>('');
    const [burialStartTime, setBurialStartTime] = useState<string>('');
    const [burialEndTime, setBurialEndTime] = useState<string>('');

    const [isTribute, setIsTribute] = useState<string>('s');
    const [isCremation, setIsCremation] = useState<string>('n');

    const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

    const [isSnackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<string>('');
    const [isSnackError, setSnackError] = useState<boolean>(false);

    const [lateNameError, setLateNameError] = useState<string | undefined>('');
    const [lateBirthdayError, setLateBirthdayError] = useState<string | undefined>('');
    const [lateDeathdayError, setLateDeathdayError] = useState<string | undefined>('');
    const [lateCPFError, setLateCPFError] = useState<string | undefined>('');


    const handleChangeLateName = (event: React.ChangeEvent<{ value: unknown }>) => {
        setLateName(event.target.value as string);
        setLateNameError('');
    };

    const handleChangeLateCPF = (event: React.ChangeEvent<{ value: unknown }>) => {
        setLateCPF(event.target.value as string);
        setLateCPFError('');
    };

    const handleChangeLateBirthday = (event: React.ChangeEvent<{ value: unknown }>) => {
        setLateBirthday(event.target.value as string);
        setLateBirthdayError('');
    };

    const handleChangeLateDeathday = (event: React.ChangeEvent<{ value: unknown }>) => {
        setLateDeathday(event.target.value as string);
        setLateDeathdayError('');
    };

    const handleChangeFuneralLocation = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFuneralLocation(event.target.value as string);
    };

    const handleChangeFuneralState = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFuneralState(event.target.value as string);
    };

    const handleChangeFuneralCity = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFuneralCity(event.target.value as string);
    };

    const handleChangeFuneralStart = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFuneralStart(event.target.value as string);
    };

    const handleChangeFuneralEnd = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFuneralEnd(event.target.value as string);
    };

    const handleChangeFuneralStartTime = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFuneralStartTime(event.target.value as string);
    };

    const handleChangeFuneralEndTime = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFuneralEndTime(event.target.value as string);
    };

    const handleChangeBurialLocation = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBurialLocation(event.target.value as string);
    };

    const handleChangeBurialState = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBurialState(event.target.value as string);
    };

    const handleChangeBurialCity = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBurialCity(event.target.value as string);
    };

    const handleChangeBurialStart = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBurialStart(event.target.value as string);
    };

    const handleChangeBurialEnd = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBurialEnd(event.target.value as string);
    };

    const handleChangeBurialStartTime = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBurialStartTime(event.target.value as string);
    };

    const handleChangeBurialEndTime = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBurialEndTime(event.target.value as string);
    };

    const handleChangeIsTribute = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTribute(event.target.value);
    }

    const handleChangeIsCremmation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCremation(event.target.value);
    }

    const handleChangeNotes = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNotes(event.target.value as string);
    }

    const formatDate = (dateString: string) => {
        var dateComponents = dateString.split("/");
        var day = parseInt(dateComponents[0]);
        var month = parseInt(dateComponents[1]);
        var year = parseInt(dateComponents[2]);

        const dateFormatted = new Date(year, month - 1, day).toISOString().replace("T", ' ').replace("Z", '');
        return dateFormatted;
    }

    const formatDateTime = (dateString: string, timeString: string) => {
        var dateComponents = dateString.split("/");
        var day = parseInt(dateComponents[0]);
        var month = parseInt(dateComponents[1]);
        var year = parseInt(dateComponents[2]);

        const dateTimeFormatted = new Date(year, month - 1, day).toISOString().split("T")[0] + " " + timeString + ":00";

        return dateTimeFormatted;
    }

    const sendGetTokenRequest = async () => {
        let getTokenRequest = await getToken();
        if(getTokenRequest.res && getTokenRequest.data.token !== undefined) {
            setToken("Bearer " + getTokenRequest.data.token);
        }
    }

    const sendEditAtendimentoRequest = async () => {
        let updateNomeResponsavel = "";
        let updateEmailResponsavel = "";

        if (id !== undefined) {
            const response = await getMemorial(parseInt(id));
            if (response.data !== undefined) {
                updateNomeResponsavel = response.data.relative.name;
                updateEmailResponsavel = response.data.relative.email;
            }
        }

        const data: AtendimentoRequest = {
            falecidomemorialativo: 1,
            falecidocpf: lateCPF,
            falecidonome: lateName,
            falecidodatanascimento: formatDate(lateBirthday),
            falecidodataobito: formatDate(lateDeathday),
            memorialresponsavelnome: updateNomeResponsavel,
            memorialresponsavelemail: updateEmailResponsavel
        }

        let editAtendimentoRequest = await editAtendimento(data);

        if (editAtendimentoRequest.res && editAtendimentoRequest.data !== undefined) {
            return true;
        } else {
            return false;
        }

    }

    const sendEditMemorialRequest = async () => {
        if (id !== undefined) {

            const response = await getMemorial(parseInt(id));
            if (response.data !== undefined) {
                const data: MemorialRequest = {
                    token: token,
                    data: {
                        ...response.data,
                        cpf: lateCPF,
                        name: lateName,
                        description: notes,
                        birthdayDateText: formatDate(lateBirthday),
                        deathDateText: formatDate(lateDeathday),
                        funeralLocation: funeralLocation,
                        funeralCity: funeralCity,
                        funeralState: funeralState,
                        funeralStartDateText: funeralStart ? adjustTime(formatDateTime(funeralStart, funeralStartTime)) : '',
                        funeralEndDateText: funeralEnd ? adjustTime(formatDateTime(funeralEnd, funeralEndTime)) : '',
                        burialLocation: burialLocation,
                        burialCity: burialCity,
                        burialState: burialState,
                        burialStartDateText: burialStart ? adjustTime(formatDateTime(burialStart, burialStartTime)) : '',
                        burialEndDateText: burialEnd ? adjustTime(formatDateTime(burialEnd, burialEndTime)) : '',
                        activeTribute: isTribute  ? true : false,
                        cremation: isCremation ? true : false

                    }
                }
                let editMemorialRequest = await editMemorial(data);
                if (editMemorialRequest.res && editMemorialRequest.data !== undefined) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }

        }
        else {
            return false;
        }

    }

    function validateForm() {
        if (lateName === '') {
            setLateNameError("Preencha o nome");
        } if (lateBirthday === '') {
            setLateBirthdayError("Preencha a data de nascimento");
        } if (lateDeathday === '') {
            setLateDeathdayError("Preencha a data de falecimento");
        } if (lateCPF === '') {
            setLateCPFError("Preencha o CPF");
        } else {
            editDatabasesRequests();
        }
    }

    const editDatabasesRequests = async () => {
        setIsRequestLoading(true);

        let atendimentoWebRequest = await sendEditMemorialRequest();

        if (atendimentoWebRequest) {
            let apiWebRequest = await sendEditAtendimentoRequest();

            if (apiWebRequest) {
                setSnackMessage("Memorial atualizado.")
                setSnackOpen(true);
            }
        }
        setIsRequestLoading(false);
    }

    const pad = (num: any) => ("0" + num).slice(-2);
    const convertHours = (date: Date) => {
        const d = new Date(date);
        const hours = d.getHours();
        const minutes = d.getMinutes();

        return [pad(hours), pad(minutes)].join(':');
    }

    const convertDate = (date: Date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    useEffect(() => {
        if (id !== undefined) {
            getAtendimentos(parseInt(id)).then((response) => {
                if (response.data !== undefined) {
                    setLateName(response.data.memoriais[0].name);
                    setLateCPF(response.data.memoriais[0].cpf);
                    setLateBirthday(convertDate(response.data.memoriais[0].birthday_date));
                    setLateDeathday(convertDate(response.data.memoriais[0].death_date));
                    setNotes(response.data.memoriais[0].description);
                    setFuneralLocation(response.data.memoriais[0].funeral_location);
                    setFuneralState(response.data.memoriais[0].funeral_state);
                    setFuneralCity(response.data.memoriais[0].funeral_city);
                    setBurialLocation(response.data.memoriais[0].burial_location);
                    setBurialState(response.data.memoriais[0].burial_state);
                    setBurialCity(response.data.memoriais[0].burial_city);
                    setIsCremation(!!response.data.memoriais[0].cremation ? 's' : 'n');
                    setIsTribute(!!response.data.memoriais[0].active_tribute ? 's' : 'n');
                    setBurialEndTime(response.data.memoriais[0].burial_end_date ? convertHours(response.data.memoriais[0].burial_end_date) : '');
                    setBurialStartTime(response.data.memoriais[0].burial_start_date ? convertHours(response.data.memoriais[0].burial_start_date) : '');
                    setFuneralEndTime(response.data.memoriais[0].funeral_end_date ? convertHours(response.data.memoriais[0].funeral_end_date) : '');
                    setFuneralStartTime(response.data.memoriais[0].funeral_start_date ? convertHours(response.data.memoriais[0].funeral_start_date) : '');
                    setBurialEnd(response.data.memoriais[0].burial_end_date ? convertDate(response.data.memoriais[0].burial_end_date) : '');
                    setBurialStart(response.data.memoriais[0].burial_start_date ? convertDate(response.data.memoriais[0].burial_start_date) : '');
                    setFuneralEnd(response.data.memoriais[0].funeral_end_date ? convertDate(response.data.memoriais[0].funeral_end_date) : '');
                    setFuneralStart(response.data.memoriais[0].funeral_start_date ? convertDate(response.data.memoriais[0].funeral_start_date) : '');
                }
            });
        }
    }, [id]);

    useEffect(() => {
        sendGetTokenRequest();
    }, []);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <PeopleAltIcon />
                <Typography component="h1" variant="h5">
                    Editar Atendimento
                </Typography>
                <div className={classes.form}>
                    <div style={{ width: '100%', height: '20px' }}>{isRequestLoading && (<LinearProgress />)}</div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography component="h1" variant="h6">
                                Ente querido(a)
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Ativar tributo</FormLabel>
                                <RadioGroup row aria-label="tribute" name="tribute1" value={isTribute} onChange={handleChangeIsTribute}>
                                    <FormControlLabel value='s' control={<Radio />} label="Sim" />
                                    <FormControlLabel value='n' control={<Radio />} label="Não" />

                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                name="lateName"
                                variant="outlined"
                                required
                                fullWidth
                                id="lateName"
                                label="Nome completo"
                                autoFocus
                                value={lateName}
                                onChange={handleChangeLateName}
                                error={!!lateNameError}
                                helperText={lateNameError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                id="lateCPF"
                                label="CPF"
                                name="lateCPF"
                                value={lateCPF}
                                onChange={handleChangeLateCPF}
                                error={!!lateCPFError}
                                helperText={lateCPFError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                                InputProps={{
                                    inputComponent: CPFMask as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                id="lateBirthday"
                                label="Data de Nascimento"
                                name="lateBirthday"
                                onChange={handleChangeLateBirthday}
                                value={lateBirthday}
                                error={!!lateBirthdayError}
                                helperText={lateBirthdayError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                                InputProps={{
                                    inputComponent: DateMask as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                id="lateDeathdate"
                                label="Data de Falecimento"
                                name="lateDeathdate"
                                autoComplete="email"
                                onChange={handleChangeLateDeathday}
                                value={lateDeathday}
                                error={!!lateDeathdayError}
                                helperText={lateDeathdayError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                                InputProps={{
                                    inputComponent: DateMask as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={2}
                                variant="outlined"
                                fullWidth
                                name="notes"
                                label="Descrição"
                                type="notes"
                                id="notes"
                                onChange={handleChangeNotes}
                                value={notes}
                                inputProps={{ maxLength: 2000 }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography component="h1" variant="h6">
                                Velório
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="funeralLocation"
                                label="Local"
                                name="funeralLocation"
                                autoComplete="address"
                                onChange={handleChangeFuneralLocation}
                                value={funeralLocation}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="funeralState"
                                label="Estado"
                                name="funeralState"
                                autoComplete="state"
                                onChange={handleChangeFuneralState}
                                value={funeralState}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="funeralCity"
                                label="Cidade"
                                name="funeralCity"
                                autoComplete="city"
                                onChange={handleChangeFuneralCity}
                                value={funeralCity}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="funeralStartDate"
                                label="Data de Início"
                                name="funeralStartDate"
                                onChange={handleChangeFuneralStart}
                                value={funeralStart}
                                InputProps={{
                                    inputComponent: DateMask as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="funeralStartTime"
                                label="Hora de Início"
                                name="funeralStartTime"
                                onChange={handleChangeFuneralStartTime}
                                value={funeralStartTime}
                                InputProps={{
                                    inputComponent: HourMask as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="funeralEndDate"
                                label="Data de Fim"
                                name="funeralEndDate"
                                onChange={handleChangeFuneralEnd}
                                value={funeralEnd}
                                InputProps={{
                                    inputComponent: DateMask as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="funeralEndTime"
                                label="Hora de Fim"
                                name="funeralEndTime"
                                onChange={handleChangeFuneralEndTime}
                                value={funeralEndTime}
                                InputProps={{
                                    inputComponent: HourMask as any,
                                }}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography component="h1" variant="h6">
                                Sepultamento
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Cremação</FormLabel>
                                <RadioGroup row aria-label="gender" name="gender1" value={isCremation} onChange={handleChangeIsCremmation}>
                                    <FormControlLabel value='s' control={<Radio />} label="Sim" />
                                    <FormControlLabel value='n' control={<Radio />} label="Não" />

                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="burialLocation"
                                label="Local"
                                name="burialLocation"
                                autoComplete="address"
                                onChange={handleChangeBurialLocation}
                                value={burialLocation}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="burialState"
                                label="Estado"
                                name="burialState"
                                autoComplete="state"
                                onChange={handleChangeBurialState}
                                value={burialState}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="burialCity"
                                label="Cidade"
                                name="burialCity"
                                autoComplete="city"
                                onChange={handleChangeBurialCity}
                                value={burialCity}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="burialStart"
                                label="Data de Início"
                                name="burialStart"
                                onChange={handleChangeBurialStart}
                                value={burialStart}
                                InputProps={{
                                    inputComponent: DateMask as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="burialStart"
                                label="Hora de Início"
                                name="burialStart"
                                onChange={handleChangeBurialStartTime}
                                value={burialStartTime}
                                InputProps={{
                                    inputComponent: HourMask as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="burialEnd"
                                label="Data de Fim"
                                name="burialEnd"
                                onChange={handleChangeBurialEnd}
                                value={burialEnd}
                                InputProps={{
                                    inputComponent: DateMask as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="burialStart"
                                label="Hora de Fim"
                                name="burialStart"
                                onChange={handleChangeBurialEndTime}
                                value={burialEndTime}
                                InputProps={{
                                    inputComponent: HourMask as any,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end">
                        <Grid item xs={12} sm={4}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => validateForm()}
                                disabled={isRequestLoading}
                            >
                                Editar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <SnackBar message={snackMessage} isOpen={isSnackOpen} severity={isSnackError ? "error" : "success"} handleClose={() => setSnackOpen(false)} />
        </Container>
    );
}