import { createMuiTheme } from '@material-ui/core';
import { Colors } from './styles';


export const theme = createMuiTheme({
    palette: {
        primary: {
            main: Colors.background.primary,
            contrastText: Colors.foreground.primary,
            dark: Colors.background.primary,
          },
          secondary: {
            main: Colors.secondary,
            contrastText: Colors.foreground.primary,
        }
    },
    overrides: {
        MuiButton: {
            root: {
              fontWeight: 500,
              fontSize: 14,
        
              height: 48,
              '&$disabled': {
                backgroundColor: '#ECECEC',
                color: 'rgba(51, 51, 51, 0.38)',
              },
            },
            text: {
              color: 'white',
              hover: {
                color: Colors.primary,
                
              },
              boxShadow: 'none',
            },
            textSecondary: {
              '&:hover': {
                color: Colors.primary,
               
              },
              boxShadow: 'none',
            },
            containedSecondary: {
              '&:hover': {
                color: Colors.primary,
                
              },
              boxShadow: 'none',
            },
          }
    }

});