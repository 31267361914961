import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const signIn = async (user: string, password: string) => {
    try {
      const body = {email: user, password: password}
      const response = await api.post("api/auth/signin", body);
      return { res: true, data: response.data };
    } catch (error) {
      return { res: false, data: '' };
    }
  };