import axios from 'axios';
import { Session } from '../../components/NavRoute/NavRoute';
import { Atendimento } from '../../models/atendimentoResponse';
import { CreateMemorialResponse } from '../../models/createMemorialResponse';
import { MemorialRequest } from '../../models/memorialRequest';
const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL
});

export const getAtendimentos = async (id: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.get(`/api/memorial?id=${id}`, {
      headers: {
        "x-access-token": token
      }
    });
    const dataResponse: Atendimento = response.data;
    return { res: true, data: dataResponse };
  } catch (error) {
    return { res: false, errorData: '' };
  }
};

export const getMemorial = async(id: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.get(`/api/memorial/${id}`, {
      headers: {
        "x-access-token": token
      }
    });
    const dataResponse: CreateMemorialResponse = response.data;
    return { res: true, data: dataResponse };
  } catch (error) {
    return { res: false, errorData: '' };
  }
}

interface getTokenRequest {
  res: boolean;
  data: {
    message: string;
    token: string;
    res: boolean;
  }
}

export const getToken = async (): Promise<getTokenRequest> => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.get("/api/memorial/token", {
      headers: {
        "x-access-token": token
      }
    });
    return { res: true, data: response.data };
  } catch (error) {
    return { res: false, data: { message: '', token: '', res: false } };
  }
};

export const editMemorial = async (data: MemorialRequest) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.put("/api/memorial", data, {
      headers: {
        "x-access-token": token
      }
    });
    const dataResponse : CreateMemorialResponse = response.data;
    return { res: true, data: dataResponse };
  } catch (error) {
    return { res: false, errorData: '' };
  }
};

export const editAtendimento = async (data: MemorialRequest) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.put("/api/atendimento", data, {
      headers: {
        "x-access-token": token
      }
    });
    const dataResponse : CreateMemorialResponse = response.data;
    return { res: true, data: dataResponse };
  } catch (error) {
    return { res: false, errorData: '' };
  }
};
