export const Colors = {
    primary: '#b08c37',
    secondary: '#0e4b82',
    white: '#FFFFFF',
    black: '#000000',
    background: {
        primary: '#b08c37',
        secondary: '#F5F5F5',
        hover: 'rgba(14, 75, 130, 0.12)',
        click: '#C2D2DF',
    },
    status: {
        positive: '#1CC861',
        negative: '#ED0000',
    },
    lightGray: 'rgba(219, 219, 219, 0.7)',
    gray: '#8C8C8C',
    foreground: {
        primary: '#FFFFFF',
        primaryBlack: '#333333',
        secondaryLight: '#DBDBDB',
        hover: '#FFFFFF',
    },
    text: {
        secondary: {
            placeholder: '#717171',
        },
    },
};

