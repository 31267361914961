import React, { useEffect, useState } from "react";
import {
  Container,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import CssBaseline from "@material-ui/core/CssBaseline";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { getAtendimentos } from "./services";
import { Memorials } from "../../models/atendimentoResponse";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(8, 0, 2),
  },
  formControl: {
    width: "100%",
  },
  helperText: {
    marginLeft: 0,
  },
  progress: {
    marginTop: "1em",
  },
  divider: {
    margin: "1em 0",
  },
  alert: {
    marginBottom: "1em",
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export default function ListMemorials() {
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [memorialsList, setMemorialsList] = useState<Memorials[]>([]);

  useEffect(() => {
    getAtendimentos(page, rowsPerPage).then((response) => {
      if (response.data !== undefined) {
        setMemorialsList(response.data?.memoriais);
        setTotal(response.data?.totalItems);
        setTotalPages(response.data?.totalPages);
      }
    });
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const backPagination = () => {
    page <= totalPages && setPage(page - 1);
  };

  const nextPagination = () => {
    page < totalPages && setPage(page + 1);
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <PeopleAltIcon />
        <Typography component="h1" variant="h5">
          Lista de Atendimentos
        </Typography>
        <div style={{ width: "100%", height: "20px" }}>
          {false && <LinearProgress />}
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Nome</StyledTableCell>
                <StyledTableCell align="center">Editar</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {memorialsList.length > 0 &&
                memorialsList.map((user) => (
                  <StyledTableRow key={user.id}>
                    <StyledTableCell align="left" className="leftspacingcell">
                      {user.name}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <IconButton
                        onClick={() => history.push(`/atendimentos/${user.id}`)}
                      >
                        <EditIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            labelRowsPerPage="Mostrar: "
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            backIconButtonProps={{
              "aria-label": "Página anterior",
              onClick: () => backPagination(),
            }}
            nextIconButtonProps={{
              "aria-label": "Próxima página",
              onClick: () => nextPagination(),
            }}
          />
        </TableContainer>
      </div>
    </Container>
  );
}
